import React from 'react'

import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import { EventHero } from '@components/Events'
import EventTabs from '@components/Events/EventTabs'

const Events = () => (
  <Layout>
    <SEO title="Events" />
    <EventHero />
    <EventTabs />
  </Layout>
)

export default Events
