import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { Container, Flex } from 'theme-ui'
import Img from 'gatsby-image'

export const EventHero = () => {
  const data = useStaticQuery(
    graphql`
      query {
        heroImage: file(relativePath: { eq: "EventHero.jpg" }) {
          childImageSharp {
            fixed(height: 1066, width: 3581) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
    <Flex>
      <Img
        fixed={data.heroImage.childImageSharp.fixed}
        key={data.heroImage.childImageSharp.fixed.src}
        alt="Event hero image"
        style={{
          height: ['300px'],
        }}
      />
    </Flex>
  )
}
